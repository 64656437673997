import { TFunction } from 'i18next';
import { translations } from '@shippypro/translations';

import { PrevalidationResult } from '../stores/orders-health-check/orders-health-check-store.types';

export function mapPrevalidationResultsTranslations(
  results: PrevalidationResult[],
  t: TFunction,
): string[] {
  const transPrevalidation = translations.order.prevalidation;
  return results
    .map(result => {
      return Object.entries(result)
        .map(([group, value]) => {
          return Object.entries(value).map(([field, rules]) => {
            try {
              return Object.values(rules).map(rule =>
                t(transPrevalidation.rules[group][field][rule]),
              );
            } catch (error) {
              console.error(error);
              return [''];
            }
          });
        })
        .flat()
        .flat();
    })
    .flat()
    .filter(message => message !== '');
}
